import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { onLogin } from '@root-auth/store/actions/auth/onLogin'
import { modeURL } from '@root-auth/App'
import MuiButton from '../MuiComponents/MuiButton'
import './Login.scss'

export interface ILogin {
	onLogin: (login: string, password: string | number) => void
	accessToken: string
	loginFromState: string
}

const Login: React.FC<ILogin> = ({ onLogin, loginFromState }) => {
	const [login, setLogin] = useState('')
	const [password, setPassword] = useState('')

	useEffect(() => {
		if (loginFromState) window.location.href = modeURL
	}, [loginFromState])

	return (
		<div className='login'>
			<Link to='/'>
				<img className='login__image' src='/assets/images/SVG/logo.svg' alt='LOGO' />
			</Link>
			<form
				className='login__form'
				onSubmit={e => {
					e.preventDefault()
					onLogin(login, password)
				}}
			>
				<h2 className='login__title'>Логин</h2>
				<div className='login__fields'>
					<TextField
						label='Email'
						variant='outlined'
						fullWidth
						type='email'
						name='email'
						onChange={e => setLogin(e.currentTarget.value)}
						value={login}
						inputProps={{ autoComplete: 'on', inputMode: 'email' }}
					/>
					<TextField
						label='Пароль'
						variant='outlined'
						fullWidth
						type='password'
						name='password'
						onChange={e => setPassword(e.currentTarget.value)}
						value={password}
						inputProps={{ autoComplete: 'on', inputMode: 'text' }}
					/>
				</div>
				<div className='login__actions'>
					<MuiButton fullWidth variant='contained' color='primary' type='submit'>
						Войти в систему
					</MuiButton>
					<Link to='/recall' className='login__actions-item link-blue'>
						Забыли пароль?
					</Link>
					<div className='login__actions-item'>
						Нет аккаунта?&nbsp;
						{/*<Link to='/registration' className='link-blue'>*/}
						{/*	Создать*/}
						{/*</Link>*/}
						{/* <a href='https://gi-pro.ru/registration' className='link-blue'>
							Создать
						</a> */}
						<Link to='/registration' className='link-blue'>
							Создать
						</Link>
					</div>
				</div>
			</form>

			<form
				className='demo__form'
				onSubmit={e => {
					e.preventDefault()
					onLogin('demo@demo.demo', 'demodemo1')
				}}
			>
				<div className='login__actions'>
					<MuiButton fullWidth variant='contained' color='secondary' type='submit'>
						Гостевой вход
					</MuiButton>
				</div>
			</form>






		</div>
	)
}

const mapStateToProps = (state: any) => ({
	loginFromState: state.auth.login,
})

const mapDispatchToProps = (dispatch: any) => ({
	onLogin: (login: string, password: string | number) => dispatch(onLogin(login, password)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
